export const environments = [
  {
    id: '',
    name: 'None',
    path: null,
    format: '.hdr'
  },
    {
        id: 'stripes-custom',
        name: 'Stripes_custom (HDR Labs)',
        path: 'assets/environment/Stripes_custom.hdr',
        format: '.hdr'
    },
];
